
.table-label{
  background: #B0BEC5;
  color: black;
  border: 1px solid grey;
}

.table-value{
  border: 1px solid grey;
}

.legend {
  position: relative;
  border: 1px solid #DCDCDC;
  padding: 1.5rem 1rem 1rem 1rem;
  margin-bottom: 1rem;
  border-radius: 3px;
  margin-top: 1rem;
}

.legend-title {
  background-color: inherit;
  position: absolute;
  top: -0.6rem;
  padding: 0.2rem 1rem;
  background-color: white;
  color: #f0bb0d;
}
