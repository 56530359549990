.main-content-index {
  font-family: "Lato", sans-serif;
  background-color: #BAF295;
}

.data{
  height: 100vh;
}

.image-data{
  height: 100vh;
  img{
    margin-top: 800px;
  }

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;

    a{
      text-decoration: none;
      color: #0a0e14;
      font-size: 80%;
      font-weight: 400;
    }

    }
  }
