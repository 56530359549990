.sideMenu{
  z-index: 5 !important;
}

.pointer{
  cursor: pointer;
}

.div-table-row {
  padding: 0 15px 0 15px;
}

.div-table {
  padding: 9px 8px 9px 8px;
}

.div-table-title {
  width: 250px;
  background-color: #b3e5fc;
  font-weight: normal;
}

.div-table-value {
  border: 1px solid #DCDCDC;
  font-weight: 900;
}

label {
  position: relative;
  top: -7px;
  right: 0;
  margin: 0;
  padding: 0;
}
tbody tr {
  color: #212529 !important;
  font-weight: 400 !important;
}

thead th {
  font-weight: 600 !important;
}

.legend {
  position: relative;
  border: 1px solid #DCDCDC;
  padding: 1.5rem 1rem 1rem 1rem;
  margin-bottom: 1rem;
  border-radius: 3px;
  margin-top: 1rem;
}

.legend-title {
  position: absolute;
  top: -0.6rem;
  padding: 0.2rem 1rem;
  background-color: #fafafa;
  color: #f0bb0d;
}


